<template>
  <div :class="{ 'preview-style': preview }" id="ds-main-wrapper">
    <div class="inner">
      <Parser :media="presentationData.media" :screen-type="screenType" />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import Parser from '@/components/parsers/Parser'

export default {
  name: 'Main',
  props: {
    preview: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    Parser,
  },
  computed: {
    ...mapState(['presentationData']),
    screenType() {
      const { presentation_display_type } = this.presentationData ?? {}
      return presentation_display_type === 'four screen' ? '4' : ''
    },
  },
  methods: {
    ...mapActions({
      dispatchPresentationData: 'dispatchPresentationData',
    }),
    async setPresentationDetails() {
      let { ownerId, facilityId, presentationId } = this.$route.params
      await this.dispatchPresentationData({
        ownerId,
        facilityId,
        presentationId,
      })
    },
  },
  async mounted() {
    this.setPresentationDetails()
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
/* #example-1 {
  transform: translate(2%, -18%) scale(0.569027);
} */
.preview-style {
  border: 0px;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.preview-style .inner {
  width: 95%;
  height: 95%;
  box-shadow: 0 0 38px 0 #000;
}
</style>
