export default function getEnv(variableName) {
  variableName = 'VUE_APP_' + variableName
  let value
  try {
    value = window.configs[variableName]
  } catch (e) {
    value = process.env[variableName]
  }
  return value
}
