<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  head: {
    script: [
      {
        src: 'https://fast.wistia.com/assets/external/E-v1.js',
      },
      {
        src: 'https://fast.wistia.net/assets/external/iframe-api-v1.js',
      },
    ],
  },
}
</script>

<style>
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  /* outline: 1px solid brown !important; */
}

@font-face {
  font-family: 'fontello';
  src: url(./assets/font/fontello.ttf);
}

@font-face {
  font-family: 'Gotham';
  src: url(./assets/font/Gotham-Book.ttf) format('truetype'),
    url(./assets/font/Gotham-Book.woff) format('woff'),
    url(./assets/font/Gotham-Book.woff2) format('woff2');
}
body {
  margin: 0;
}
#app {
  font-family: 'Gotham';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
