/**
 * ************************************************************************************************
 *                                                                                                *
 * Title: Hours logic                                                                             *                 *
 * Desc : This sections contains all the helper functions used in building hours logic            *
 *                                                                                                *
 * ************************************************************************************************
 **/

import dayjs from 'dayjs'
let DAY_NAME_MAPPING = {
  sunday: 'SUN',
  monday: 'MON',
  tuesday: 'TUE',
  wednesday: 'WED',
  thursday: 'THU',
  friday: 'FRI',
  saturday: 'SAT',
}
/**
 * Sorts array of hours objects (default: Monday - Sunday)
 * @param {Array} day - Array of hours objects
 * @returns {Array} Sorted array of objects.
 */
function sortDays(day) {
  let days = [...day]
  const daysOfWeek = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun']
  return days.sort(function (a, b) {
    return daysOfWeek.indexOf(a.day) - daysOfWeek.indexOf(b.day)
  })
}

/**
 * Return label for hour object
 * @param {Object} dayObj - hour object
 * @returns {String} Return corresponding label
 */
function generateDayLabel(dayObj) {
  let convertTime = (time) => {
    let meridium = time.split(':')
    return `${dayjs(time, 'h:mm:ss').format('h:mm')} ${
      meridium[meridium.length - 1].split(' ')[1]
    }`
  }
  let label
  if (dayObj.appointment_only) label = 'Appointment Only'
  else if (dayObj['24_hours']) label = 'Always Open'
  else if (dayObj.open_time === dayObj.close_time) label = 'Closed'
  else
    label = `${convertTime(dayObj.open_time)} - ${convertTime(
      dayObj.close_time
    )}`
  return label
}

/**
 * Assign labels for each hour object
 * @param {Array} groupedDays - Array of objects thats grouped
 * @returns {Array} Return array of objects with label and value
 */
function generateGroupLabels(groupedDays = []) {
  let today = Object.keys(DAY_NAME_MAPPING)[new Date().getDay()]
  return groupedDays.reduce((acc, curr, index) => {
    let tempObj = {}
    switch (curr.days.length) {
      case 1:
        tempObj = { value: DAY_NAME_MAPPING[curr.days[0]] }
        break
      case 2:
        tempObj = {
          value: `${DAY_NAME_MAPPING[curr.days[0]]} - ${
            DAY_NAME_MAPPING[curr.days[curr.days.length - 1]]
          }`,
        }
        break
      case 7:
        tempObj = { value: 'Daily' }
        break
      default:
        tempObj = {
          value: `${DAY_NAME_MAPPING[curr.days[0]]} - ${
            DAY_NAME_MAPPING[curr.days[curr.days.length - 1]]
          }`,
        }
        break
    }
    Object.assign(tempObj, {
      label: groupedDays[index].hoursLabel,
      includes_today: curr.days.includes(today),
    })
    acc.push(tempObj)
    return acc
  }, [])
}

/**
 * Return compressed array of hours objects with label and value
 * @param {Array} days - Array of hours objects
 * @returns {Array} Sorted array of array of objects
 */
export function compressScheduledHours(days) {
  let sortedDays = sortDays(days)
  const groupedDays = []
  let lastEntry
  for (let i = 0; i < sortedDays.length; i++) {
    const dayObj = sortedDays[i]
    const hoursLabel = generateDayLabel(dayObj)
    if (
      lastEntry &&
      hoursLabel === lastEntry.hoursLabel &&
      i - lastEntry.index === 1
    ) {
      lastEntry.days.push(dayObj.day)
      lastEntry.index = i
    } else {
      lastEntry = {
        hoursLabel,
        days: [dayObj.day],
        index: i,
      }
      groupedDays.push(lastEntry)
    }
  }
  return generateGroupLabels(groupedDays)
}
