import axios from 'axios'
import getEnv from '../utils/getEnv.js'

console.log(process.env)
const gdsAxios = axios.create({
  baseURL: getEnv('GDS_API_URL'),
  headers: {
    'Content-Type': 'application/json',
    'x-storageapi-key': getEnv('GDS_API_KEY'),
  },
})

gdsAxios.interceptors.request.use(function (config) {
  const timestamp = new Date().getTime().toString().slice(0, -3)
  config.headers['x-storageapi-date'] = timestamp
  return config
})

gdsAxios.interceptors.response.use(
  function (response) {
    return Object.values(response.data.applicationData)[0][0]
  },
  function (error) {
    return Object.values(error.response.data.applicationData)[0][0]
  }
)

export async function getPresentationDetails(
  ownerId,
  facilityId,
  presentationId
) {
  let response = await gdsAxios.get(
    '/applications/' +
      getEnv('GDS_DIGITAL_SIGNAGE_APP_ID') +
      '/v1/owners/' +
      ownerId +
      '/facilities/' +
      facilityId +
      '/presentations/' +
      presentationId +
      '/'
  )

  return response.data
}

export async function getFacilityReviews(ownerId, facilityId) {
  let response = await gdsAxios.get(
    '/applications/' +
      getEnv('GDS_WEBSITE_APP_ID') +
      '/v1/owners/' +
      ownerId +
      '/website' +
      '/facilities/' +
      facilityId +
      '/reviews/'
  )
  return response.data
}

export async function getFacilityInsurances(ownerId, facilityId) {
  let response = await gdsAxios.get(
    '/applications/' +
      getEnv('GDS_CMS_APP_ID') +
      '/v1/owners/' +
      ownerId +
      '/website' +
      '/facilities/' +
      facilityId +
      '/facility-insurances/'
  )
  return response.data
}

export async function getFacilityHours(ownerId, facilityId) {
  let response = await gdsAxios.get(
    '/applications/' +
      getEnv('GDS_CMS_APP_ID') +
      '/v1/owners/' +
      ownerId +
      '/website' +
      '/facilities/' +
      facilityId +
      '/facility-hours/'
  )
  return response.data
}
