import Vue from 'vue'
import Vuex from 'vuex'

import {
  getPresentationDetails,
  getFacilityReviews,
  getFacilityInsurances,
  getFacilityHours,
} from '@/api/api.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    presentationData: {},
    facilityInsurances: [],
    facilityReviews: [],
    facilityHours: [],
  },
  mutations: {
    setPresentationData(state, data) {
      state.presentationData = data
    },
    setFacilityInsurances(state, data) {
      state.facilityInsurances = data
    },
    setFacilityReviews(state, data) {
      state.facilityReviews = data
    },
    setFacilityHours(state, data) {
      state.facilityHours = data
    },
  },
  actions: {
    async dispatchPresentationData(
      { commit },
      { ownerId, facilityId, presentationId }
    ) {
      let [
        presentationData,
        facilityReviews,
        facilityInsurances,
        facilityHours,
      ] = await Promise.allSettled([
        getPresentationDetails(ownerId, facilityId, presentationId),
        getFacilityReviews(ownerId, facilityId),
        getFacilityInsurances(ownerId, facilityId),
        getFacilityHours(ownerId, facilityId),
      ])
      commit('setPresentationData', presentationData.value)
      commit('setFacilityInsurances', facilityInsurances.value)
      commit('setFacilityReviews', facilityReviews.value)
      commit('setFacilityHours', facilityHours.value)
    },
  },
  modules: {},
})
