<template>
  <div id="test">
    <vueper-slides
      v-if="compressedMediaData.length"
      :arrows="false"
      :bullets="false"
      :autoplay="!isSingleSlide && autoplay"
      ref="slideDoom"
      :slide-ratio="window.height / window.width"
      :pause-on-hover="false"
    >
      <vueper-slide
        v-for="(slide, idx) in compressedMediaData"
        :key="idx"
        :duration="slide.duration"
        :image="getSlideImage(slide)"
      >
        <template #content>
          <div class="video-wrapper" :id="idx" v-if="slide.video">
            <iframe :src="slide.video.url"></iframe>
          </div>
          <component
            :is="getComponent(slide.type)"
            class="slide-intraction"
            :slide-data="slide"
          />
        </template>
      </vueper-slide>
    </vueper-slides>
  </div>
</template>

<script>
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'

import feature from '@/components/single_screen/feature'
import feature_4 from '@/components/four_screen/feature_4'

import local from '@/components/single_screen/local'
import local_4 from '@/components/four_screen/local_4'

import insurance from '@/components/single_screen/insurance'
import insurance_4 from '@/components/four_screen/insurance_4'

import hoursOpen_4 from '@/components/four_screen/hoursOpen_4'
import hoursOpen from '@/components/single_screen/hoursOpen'

import hoursClosed_4 from '@/components/four_screen/hoursClosed_4'

import review from '@/components/single_screen/review'
import review_4 from '@/components/four_screen/review_4'

export default {
  components: {
    VueperSlides,
    VueperSlide,
    feature,
    local,
    local_4,
    feature_4,
    insurance,
    insurance_4,
    hoursOpen,
    hoursOpen_4,
    hoursClosed_4,
    review,
    review_4,
  },
  props: {
    media: {
      type: Array,
      required: true,
      default: () => [],
    },
    screenType: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      autoplay: true,
      window: {
        height: 0,
        width: 0,
      },
    }
  },
  computed: {
    compressedMediaData() {
      return this.media
        .filter((file) => this.hasContent(file))
        .map((mediaFile) => {
          return {
            image: mediaFile.media.image_url,
            duration: mediaFile.duration * 1000,
            include_copy: mediaFile.include_copy,
            title: mediaFile.media.title,
            type: mediaFile.template,
            slide_text: mediaFile.slide_text,
            background_color: mediaFile.background_color,
            text_color: mediaFile.text_color,
            review_count: mediaFile.review.count,
            review_rating: mediaFile.review.rating_options,
            insurance_text: mediaFile.insurance_text,
            ...(mediaFile.media.video_id && {
              video: {
                url: this.getVideoURL(mediaFile.media.video_id),
                props: {
                  controls: true,
                },
              },
            }),
          }
        })
    },
    isSingleSlide() {
      return this.compressedMediaData?.length === 1
    },
  },
  mounted() {
    let self = this
    window._wq = window._wq || []
    window._wq.push({
      id: '_all',
      onReady: function (video) {
        video.bind('play', function () {
          self.autoplay = false
        })

        video.bind('end', function () {
          self.autoplay = true
        })
      },
    })

    this.window.width = window.innerWidth
    this.window.height = window.innerHeight
  },
  methods: {
    getVideoURL(video_id) {
      return video_id
        ? `https://fast.wistia.net/embed/iframe/${video_id}?videoFoam=true&autoplay=true&endVideoBehavior=loop&muted=true`
        : ''
    },
    getSlideImage(slide) {
      return slide?.video?.url ? '' : slide.image
    },
    /**
     * Return component name based on template
     */
    getComponent(slideType) {
      let slideName = ''
      switch (slideType) {
        case 'features':
          slideName = 'feature'
          break
        case 'local':
          slideName = 'local'
          break
        case 'insurance':
          slideName = 'insurance'
          break
        case 'hours open':
          slideName = 'hoursOpen'
          break
        case 'review':
          slideName = 'review'
          break
        case 'hours closed':
          slideName = 'hoursClosed'
          break
        default:
          break
      }

      return slideName && this.screenType
        ? `${slideName}_${this.screenType}`
        : slideName
    },
    hasContent(slide) {
      console.log(slide)
      return (
        slide.is_active &&
        (slide.media.image_url || slide.media.video_id || slide.slide_text)
      )
    },
  },
}
</script>

<style scoped>
#overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 10%;
  height: 10%;
  background: #000;
  opacity: 0.8;
  /*background:rgba(255,255,255,0.8); or just this*/
  z-index: 50;
  color: #fff;
}
/* #test {
  width: 1920px;
  height: 1080px;
  padding: 25px;
  text-align: center;
  background: white;
  position: relative;
  left: 7%;
} */
.vueperslides {
  position: relative;
  border: 0px;
  width: 100%;
  height: 91%;
  /* box-shadow: 0 0 38px 0 #000; */
}
\ .video-wrapper {
  position: relative;
}
.video-wrapper iframe {
  position: absolute;
  width: 100vw;
  top: 0;
  left: 0;
  height: 100vh;
  z-index: -1;
}
</style>
