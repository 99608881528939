import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '@/components/Main.vue'
import VueMeta from 'vue-meta'
import NotFound from '@/components/NotFound.vue'

Vue.use(VueRouter)
Vue.use(VueMeta, {
  keyName: 'head',
})

const routes = [
  {
    path: '/owners/:ownerId/facilities/:facilityId/presentations/:presentationId/',
    name: 'index',
    component: Main,
  },
  {
    path: '*',
    name: 'unknown',
    component: NotFound,
  },
  {
    path: '/owners/:ownerId/facilities/:facilityId/presentations/:presentationId/preview',
    name: 'preview',
    component: Main,
    props: { preview: true },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
